import { useState } from 'react';
import '../css/signup.css';

export default function SignUp() {
  const [email, setEmail] = useState('');
  const [consentCheckbox, setConsentCheckbox] = useState(false);
  const [error, setError] = useState('');

  const handleEmailInputChange = (event: any) => {
    setEmail(event.target.value);
  };

  const handleConsentCheckboxChange = () => {
    setConsentCheckbox((prevState) => !prevState);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    // Check if error occurs
    if (!email || !consentCheckbox) {
      setError('Please fill out the form and check that you have accepted to receive emails.');
      return;
    }
    setError('');

    try {
      // Prepare the data to be sent in the POST request
      const date = new Date();
      const formData = {
        email,
        date,
      };

      // Make the POST request using fetch
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData), // Convert formData to JSON
      });

      const result = await res.json();
      console.log(result); // Store the server response in state
      setEmail('');
      setConsentCheckbox(false);
    } catch (error) {
      console.error('Error during POST request:', error);
    }
  };

  return (
    <form id="sign-up" className="sign-up-container">
      {error && <div style={{ color: 'red', fontSize: '1.2rem' }}>{error}</div>}

      <h1>All Aboard!</h1>
      <p>Sign up to stay up-to-date on our alpha release!</p>

      <div className="form-floating mb-3">
        <input
          type="email"
          id="email"
          className="form-control"
          placeholder="Email"
          aria-label="Email"
          value={email}
          required
          onChange={handleEmailInputChange}
        />
        <label htmlFor="email">Email</label>
      </div>

      <div style={{ display: 'flex', gap: '1em' }}>
        <input
          type="checkbox"
          name="consent-checkbox"
          id="consent-checkbox"
          required
          checked={consentCheckbox}
          onChange={handleConsentCheckboxChange}
        />
        <label htmlFor="consent-checkbox">I agree to receive emails for important news.</label>
      </div>
      <input type="submit" value="Sign Up!" className="btn btn-primary-lg title-button" onClick={handleSubmit} />
    </form>
  );
}
